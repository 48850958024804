<div class="relative flex items-center bg-primary/10 p-2 md:p-3 rounded-1">
  <svg-icon class="search-icon" key="search" [color]="iconColor" [fontSize]="iconSize"></svg-icon>
  <input
    class="hidden md:block search-input"
    type="text"
    [formControl]="formControl"
    [mgAutofocus]="autoFocus"
    [placeholder]="placeholder"
    [value]="formValue"
    (keyup.enter)="filterButtonClicked.emit(formValue)"
  />
  <input
    class="md:hidden search-input"
    type="text"
    [formControl]="formControl"
    [mgAutofocus]="autoFocus"
    placeholder="Search"
    [value]="formValue"
    (keyup.enter)="filterButtonClicked.emit(formValue)"
  />

  <mg-button
    extraBtnClass="!w-[80px] md:!w-[120px]"
    size="small"
    [loading]="isLoading"
    (click)="filterButtonClicked.emit(formValue)"
  >
    Search
  </mg-button>
</div>
