<div *ngIf="onlyLines">
  <ng-container *ngTemplateOutlet="lines"></ng-container>
</div>

<div
  *ngIf="!onlyLines"
  class="rounded-2 p-3 bg-contrast"
  [ngClass]="{
    'shadow-e1 hover:shadow-e2': hasHoverEffect,
    'border border-gray-300': !hasHoverEffect
  }"
>
  <div class="w-full flex justify-between items-center pb-3 border-b border-gray-200">
    <span class="{{ labelClass }} text-xl font-semibold leading-2xl">{{ label }}</span>
    <div #precentageSlot>
      <ng-content select="[slot=precentage]"></ng-content>
    </div>
    <div
      *ngIf="!precentageSlot.children.length"
      class="px-2 py-0.5 rounded-full text-xl leading-2xl flex items-center"
      [ngClass]="{ 'negative-badge': precentage < 0, 'positive-badge': precentage >= 0 }"
    >
      <svg-icon
        [key]="precentage >= 0 ? 'arrow-north-east' : 'arrow-south-east'"
        [color]="precentage >= 0 ? '#4CAF50' : '#D32F2F'"
        fontSize="9px"
      ></svg-icon>
      <span class="ml-1 text-xl leading-2xl">{{ precentage }}%</span>
    </div>
  </div>

  <div class="pt-3">
    <ng-container *ngTemplateOutlet="lines"></ng-container>
  </div>

  <ng-content></ng-content>
</div>

<ng-template #lines>
  <table class="w-full">
    <tr class="border-transparent">
      <td>
        <div class="title">{{ firstLabel }}</div>
      </td>
      <td class="w-full">
        <div class="flex items-center" [style.width.%]="firstBarPercentage">
          <div class="bar-item {{ firstLineColorClass }}"></div>
          <div class="price">
            {{
              firstValueFormatted
                ? firstValueFormatted
                : withDolarSign
                ? (firstValue | currency)
                : (firstValue | number)
            }}
          </div>
        </div>
      </td>
    </tr>

    <tr class="border-t-4 border-transparent">
      <td>
        <div class="title">{{ secondLabel }}</div>
      </td>
      <td class="w-full">
        <div class="flex items-center" [style.width.%]="secondBarPercentage">
          <div class="bar-item {{ secondLineColorClass }}"></div>
          <div class="price">
            {{
              secondValueFormatted
                ? secondValueFormatted
                : withDolarSign
                ? (secondValue | currency)
                : (secondValue | number)
            }}
          </div>
        </div>
      </td>
    </tr>
  </table>
</ng-template>
