<div
  class="w-full h-full flex flex-col"
  mgEnterViewport
  (visibilityChange)="isVisible = $event; setSizes()"
>
  <div
    *ngFor="let item of info; let i = index"
    class="{{ item.colorClass }} h-2 rounded-r-full mt-1 first:mt-0 transition-[width] duration-1000"
    [style.width.%]="animatable ? sizes[i] : item.size"
  ></div>
</div>
