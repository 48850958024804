<div class="relative">
  <div class="circle-chart-contianer">
    <svg viewBox="0 0 183 165" class="w-full">
      <path
        d="M152.171108,161.958354 C148.426423,165.318314 142.670417,165.002586 139.314709,161.253156 C135.959002,157.503726 136.27433,151.740426 140.019015,148.380466 C155.66558,134.341411 164.791045,114.244562 164.791045,92.6232407 C164.791045,51.5135119 131.952628,18.2320299 91.5,18.2320299 C51.0473721,18.2320299 18.2089552,51.5135119 18.2089552,92.6232407 C18.2089552,114.583845 27.6254024,134.96561 43.6993822,149.016708 C47.4872033,152.327839 47.8770344,158.086567 44.5700945,161.879188 C41.2631546,165.671809 35.5117144,166.062134 31.7238933,162.751004 C11.7223437,145.266614 0,119.893769 0,92.6232407 C0,41.493435 40.941047,9.9475983e-14 91.5,9.9475983e-14 C142.058953,9.9475983e-14 183,41.493435 183,92.6232407 C183,119.471468 171.639786,144.489865 152.171108,161.958354 Z"
        id="Path"
        fill="#FAFAFB"
        fill-rule="nonzero"
        class="fill-transparent stroke-transparent"
      ></path>
      <path
        #line1
        d="M147.243377,157.645719 C166.052413,141.778412 178,118.03446 178,91.5 C178,43.7273691 139.272631,5 91.5,5 C43.7273691,5 5,43.7273691 5,91.5 C5,118.483477 17.355363,142.581205 36.7173551,158.444449"
        class="stroke-4 line-animation"
        [ngStyle]="{ stroke: calculateColor() }"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        #line2
        d="M143.242549,153.170568 C160.824284,138.403739 172,116.256984 172,91.5 C172,47.0410776 135.958922,11 91.5,11 C47.0410776,11 11,47.0410776 11,91.5 C11,116.421228 22.3244935,138.697479 40.1082208,153.463493"
        class="stroke-graph-2 stroke-4 line-animation"
        fill="none"
        id="Path"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </div>
  <div class="flex flex-col text-center items-center justify-center absolute w-full h-full top-0">
    <p class="leading-2xl text-3xl" [ngStyle]="{ color: calculateColor() }">{{ precentage }}%</p>
    <p class="mt-1 text-base text-txt {{ labelClass }}">{{ label }}</p>
  </div>
</div>
