import { Injectable } from '@angular/core'
import {
  AggregatorType,
  IPaginationFilteredRequest,
  ITableAction,
  ITableFilterRule,
  TableColumnType,
  TableOperator
} from './table-actions.interface'
import { DateService, environment } from '@mg-platform/core/core-util'
import { isArray } from 'lodash'
import { PeriodType } from '@mg-platform/core/core-data-access'

@Injectable({
  providedIn: 'root'
})
export class TableActionsService {
  constructor(private dateService: DateService) {}

  createRequestPayload(info: ITableAction): IPaginationFilteredRequest {
    const paylad: IPaginationFilteredRequest = {
      filters: {},
      top: environment.pageSize,
      skip: (info.page - 1) * environment.pageSize
    }

    paylad['orderBy'] = (info?.sorts ?? []).map((el) => ({
      direction: el.direction === 'asc' ? 'Ascending' : 'Descending',
      property: el.active
    }))

    paylad.filters = Object.fromEntries(
      (info?.filters ?? [])
        .filter((el) => el.items && el.items.length > 0)
        .map((el) => {
          let aggregator = el.aggregator
          let items: ITableFilterRule[] | undefined
          switch (el.columnType) {
            case TableColumnType.checkbox:
              if (isArray(el.items[0].value)) {
                items = (el.items[0].value ?? []).map((value: string) => ({
                  operator: TableOperator.Equals,
                  value
                }))
              } else {
                items = []
              }
              break

            case TableColumnType.date:
              aggregator = AggregatorType.And
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: el.value ?? PeriodType.Custom,
                extraValue: {
                  from: this.dateService.momentToDateString(el.extraValue.from),
                  to: this.dateService.momentToDateString(el.extraValue.to)
                }
              }))
              break

            default:
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: `${el.value}`
              }))
              break
          }
          return [
            el.key,
            {
              key: el.key,
              aggregator,
              items
            }
          ]
        })
    )

    return paylad
  }
}
