<ng-container *ngIf="!gridLoading">
  <div class="mt-4 pb-4 {{ containerClass }}" *ngFor="let item of configExtended">
    <div *ngIf="item.showTitles" class="mg-loading-item loading-title"></div>
    <ng-container *ngIf="item.showSubTitles">
      <div class="mg-loading-item loading-subtitle"></div>
      <div class="mg-loading-item loading-subtitle-fixed"></div>
    </ng-container>

    <div
      class="hidden"
      [ngClass]="{
        'md:flex': item.direction === 'horizontal',
        'md:block': item.direction === 'vertical'
      }"
    >
      <div
        *ngFor="let card of item.cards"
        class="mg-loading-item loading-card {{ itemClass }}"
        [style.height.px]="item.height"
        [ngClass]="{
          'mt-3 mr-4 last:mr-0': item.direction === 'horizontal',
          'mt-4 first:mt-3': item.direction === 'vertical'
        }"
      ></div>
    </div>

    <div class="flex md:hidden mt-3 -mx-3.5 pl-3.5 {{ mobileItemClass }}">
      <div class="mg-loading-item loading-card" [style.height.px]="item.mobileHeight"></div>
      <div
        class="loading-card-small"
        style="width: calc(100vw / 40)"
        [style.height.px]="item.mobileHeight"
      ></div>
    </div>
    <div class="md:hidden flex items-center justify-center mt-3">
      <div class="mg-loading-item w-7 h-5 rounded-full"></div>
    </div>
  </div>
</ng-container>

<div *ngIf="gridLoading" class="grid {{ gridLayout }}">
  <div
    class="mg-card mg-loading-item"
    *ngFor="let item of gridItemsList"
    [style.height.px]="gridItemHeight"
  ></div>
</div>
