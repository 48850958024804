<div class="flex flex-col lg:flex-row bg-ui-page print:bg-contrast">
  <div class="flex-1 min-h-screen flex flex-col">
    <div class="flex-1 pb-4">
      <div class="print:hidden">
        <div class="border-b border-gray-200">
          <div
            class="w-full py-2 lg:py-3 mx-auto header-container px-3 flex items-center justify-center lg:block border-b border-gray-200 lg:border-b-0"
            style="z-index: 1000"
          >
            <div class="flex items-center">
              <img
                class="lg:mr-9 cursor-pointer"
                src="assets/images/icons/big-logo-with-title.svg"
                [routerLink]="applicationRoutes.dashboard"
                width="142"
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="content-container mx-auto px-3">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="content-container w-full mx-auto px-3 pb-6 print:hidden">
      <div class="border-t pt-3 border-gray-200 text-lg leading-xl text-txt-secondary">
        Copyright © 2024 by Market Genie v.1.0.3. All rights reserved.
      </div>
    </div>
  </div>
</div>
