import { Sort } from '@angular/material/sort'
import { BenchmarkType, CalendarReportType, MarketType } from '@mg-platform/reports/reports-data-access'
import { ICompareWithSelection } from '../../interfaces/compare-with.interface'
import { IRangeDateInfo, ITableAction } from '@mg-platform/core/core-feature'
import { ISelectItem } from '@mg-platform/core/core-ui'

export interface FiltersStateModel {
  shopId: string
  compareWith: ICompareWithSelection
  marketType: MarketType
  weekNumber: number
  calendarReportType?: CalendarReportType
  calendarViewDate?: number
  benchmarkType: BenchmarkType
  benchmarkStartDate: number
  benchmarkEndDate: number
  benchmarkSecondCompareWith: ICompareWithSelection
  benchmarkThirdCompareWith: ICompareWithSelection
  benchmarkCustomDateInfo: IRangeDateInfo
  compTrendAvgSalesPerBay: boolean | string
  compTrendAvgCarCount: boolean | string
  compTrendAvgTicket: boolean | string
  adminShopSortState: Sort
  adminShopActiveTab: string
  adminOrganizationsFilters: ITableAction
  adminMarketPricingSortState: Sort
  grossprofitDateInfo: IRangeDateInfo
  adminExcludeBatch: boolean
  rankingsDateInfo: IRangeDateInfo
  allCountries: ISelectItem[]
}

export type FilterStateKeys = keyof FiltersStateModel
