import { MarketType } from '../enums/market-type.enum'
import { BenchmarkType } from '../enums/benchmark-type.enum'

export interface IBenchmarkFilters {
  benchmarkType: BenchmarkType
  startDate: number
  endDate: number
}

export interface IBenchmarkDetail {
  benchmarkInterval: BenchmarkType
  incomeAverage: number
  topIncomeAverage: number
  bottomIncomeAverage: number
  carsAverage: number
  topCarsAverage: number
  bottomCarsAverage: number
  ticketAverage: number
  topTicketAverage: number
  bottomTicketAverage: number
  incomeAverageFormatted: string
  topIncomeAverageFormatted: string
  bottomIncomeAverageFormatted: string
  carsAverageFormatted: string
  topCarsAverageFormatted: string
  bottomCarsAverageFormatted: string
  ticketAverageFormatted: string
  topTicketAverageFormatted: string
  bottomTicketAverageFormatted: string
  incomeIndex?: number
  carsIndex?: number
  averageTicketIndex?: number
}

export interface IBenchmarkData {
  id: string
  displayText: string
  marketType: MarketType
  benchmarkDetails: IBenchmarkDetail[]
}

export interface IBenchmarkIntervalLabels {
  lastWeek: string
  lastTwoWeeks: string
  monthToDate: string
  yearToDate: string
  custom: string
}

export interface IBenchmarkResponse {
  subTitle: string
  benchmarks: IBenchmarkData[]
  benchmarkIntervalLabels: IBenchmarkIntervalLabels
}
