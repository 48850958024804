import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Sort, SortDirection } from '@angular/material/sort'
import { UntilDestroy } from '@ngneat/until-destroy'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'mg-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnChanges {
  @Input() key: string
  @Input() label: string
  @Input() sortInfo?: Sort[] = []

  sortDirection?: SortDirection

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortInfo'].currentValue) {
      this.sortDirection = this.sortInfo?.find((el) => el.active === this.key)?.direction
    }
  }
}
