import * as Chartjs from 'chart.js'
import { DeepPartial } from 'chart.js/types/utils'

export const ChartTooltipOptions: DeepPartial<Chartjs.TooltipOptions> = {
  displayColors: false,
  caretSize: 0,
  caretPadding: 10,
  backgroundColor: '#FFFFFF',
  bodyColor: '#112027',
  titleColor: '#112027',
  borderWidth: 1,
  borderColor: '#dbdbdb',
  cornerRadius: 8,
  padding: {
    left: 16,
    right: 16,
    top: 12,
    bottom: 8
  },
  bodyFont: {
    family: 'Poppins',
    size: 14,
    lineHeight: 1.6
  },
  titleFont: {
    family: 'Poppins',
    size: 16,
    weight: '500'
  }
}
