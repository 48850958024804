<div>
  <div
    class="px-2 py-0.5 flex items-center text-lg leading-xl text-txt-secondary rounded-full border border-txt-secondary cursor-pointer"
    [ngClass]="{ '!border-primary !text-primary': sorts && sorts.length > 0 }"
    (click)="sortMenuTrigger.openMenu()"
  >
    <svg-icon key="data-transfer" fontSize="12px"></svg-icon>
    <span class="mx-1">Sort</span>
    <svg-icon key="arrow-down-simple" fontSize="12px"></svg-icon>
  </div>
  <div [matMenuTriggerFor]="sortMenu" #sortMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu
  #sortMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
  (closed)="onMenuClose()"
>
  <div
    class="px-2 pt-3 pb-1 flex flex-col overflow-auto min-w-[320px]"
    (click)="$event.stopPropagation()"
  >
    <div>
      <div
        class="mb-1 last:mb-2 flex items-center justify-between"
        *ngFor="let sort of innerSorts; let index = index"
      >
        <div class="flex items-center">
          <div class="flex text-txt-secondary" (click)="deleteSort(index)">
            <svg-icon key="dots" fontSize="11px"></svg-icon>
          </div>
          <div class="ml-2 flex items-center">
            <div class="mr-1">
              <mg-dropdown-menu
                [disable]="true"
                [selectedItem]="sort.active"
                [items]="allColumns"
              ></mg-dropdown-menu>
            </div>
            <mg-dropdown-menu
              [selectedItem]="sort.direction"
              [items]="sortDirections"
              (selectedItemChanged)="innerSorts[index].direction = $event"
            ></mg-dropdown-menu>
          </div>
        </div>
        <div class="ml-4 flex text-txt-secondary cursor-pointer" (click)="deleteSort(index)">
          <svg-icon key="close" fontSize="10px"></svg-icon>
        </div>
      </div>
    </div>

    <mg-dropdown-menu
      *ngIf="remainingColumns.length > 0"
      [items]="remainingColumns"
      (selectedItemChanged)="addSort($event)"
    >
      <div class="flex items-center text-lg leading-xl text-txt-secondary cursor-pointer">
        <svg-icon key="add" fontSize="11px"></svg-icon>
        <div class="pl-2">Add Sort</div>
      </div>
    </mg-dropdown-menu>

    <div class="mt-2 flex items-center justify-between">
      <div
        class="text-lg leading-xl text-txt-secondary cursor-pointer"
        (click)="setDefault(); sortMenuTrigger.closeMenu()"
      >
        Default
      </div>
      <div
        class="text-lg leading-xl text-primary cursor-pointer"
        (click)="emitSortChange(); sortMenuTrigger.closeMenu()"
      >
        Sort
      </div>
    </div>
  </div>
</mat-menu>
