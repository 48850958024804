<div class="flex flex-col lg:flex-row" *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="shop">
    <mg-autovitals-menu
      *ngIf="shop.isVerified && currentUser.referralType === referralTypes.autovitals"
    ></mg-autovitals-menu>
  </ng-container>

  <div class="flex-1 min-h-screen flex flex-col">
    <div class="flex-1 flex flex-col">
      <div class="lg:hidden print:hidden" [ngSwitch]="currentUser.role">
        <ng-container *ngSwitchCase="allRoles.user">
          <mg-main-header
            [mainMenuRoutes]="userMainRoutes"
            [profileMenuRoutes]="userProfileRoutes"
          ></mg-main-header>
        </ng-container>
        <ng-container *ngSwitchCase="allRoles.enterpriseuser">
          <mg-main-header
            [mainMenuRoutes]="enterpriseMainRoutes"
            [profileMenuRoutes]="enterpriseProfileRoutes"
          ></mg-main-header>
        </ng-container>
      </div>

      <div class="content-container overflow-hidden print:overflow-visible relative">
        <div
          *ngIf="window.innerWidth >= mobileMaxWidth"
          id="mg-theme-menu"
          class="print:hidden absolute w-[272px] z-[1000] top-0 right-0 h-screen bg-contrast shadow transition-transform duration-700"
          [ngClass]="{ 'translate-x-full': !themeMenuVisible, 'translate-x-0': themeMenuVisible }"
        >
          <div class="w-full h-full relative">
            <div
              class="absolute flex items-center justify-center top-5 -left-5.5 w-5.5 h-5.5 rounded-l-2 bg-ui-bg cursor-pointer text-txt"
              style="box-shadow: -1px 0px 2px -1px #c1c1c1"
              (click)="themeMenuVisible = !themeMenuVisible"
            >
              <svg-icon
                [key]="!themeMenuVisible ? 'palette' : 'arrow-right'"
                [fontSize]="!themeMenuVisible ? '16px' : '12px'"
              ></svg-icon>
            </div>

            <mg-update-theme-card></mg-update-theme-card>
          </div>
        </div>

        <div class="side-menu" [ngSwitch]="currentUser.role">
          <ng-container *ngSwitchCase="allRoles.user">
            <mg-side-menu
              [mainMenuRoutes]="userMainRoutes"
              [profileMenuRoutes]="userProfileRoutes"
            ></mg-side-menu>
          </ng-container>
          <ng-container *ngSwitchCase="allRoles.enterpriseuser">
            <mg-side-menu
              [mainMenuRoutes]="enterpriseMainRoutes"
              [profileMenuRoutes]="enterpriseProfileRoutes"
            ></mg-side-menu>
          </ng-container>
        </div>
        <div id="main-content" class="main-content beautyScroll" style="scrollbar-gutter: stable">
          <div class="outlet-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
