<div
  *ngIf="isPrint || !isMobileVersion"
  [ngClass]="{
    'h-full': fullHeight
  }"
>
  <ng-content select="[slot=desktop]"></ng-content>
</div>
<div class="-mx-3.5" *ngIf="!isPrint && isMobileVersion">
  <ng-content select="[slot=mobile]"></ng-content>
</div>
