import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { commonIcons, socialIcons } from '@mg-platform/icons'
import { CoreUiModule } from '@mg-platform/core/core-ui'
import { ToastrModule } from 'ngx-toastr'
import { environment, GlobalErrorHandler } from '@mg-platform/core/core-util'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { UserAccountState, UserInvitationState } from '@mg-platform/users/users-data-access'
import { AppRoutingModule } from './app-routing.module'
import { AuthLayoutModule } from './layout/auth-layout/auth-layout.module'
import { ShopsState, ShopUsersState } from '@mg-platform/shops/shops-data-access'
import { FiltersState, StatesState } from '@mg-platform/core/core-data-access'
import { MainLayoutModule } from './layout/main-layout/main-layout.module'
import {
  DailyReportsState,
  BenchmarkReportsState,
  MarketPricingReportsState,
  TrendReportsState,
  WeeklyReportsState,
  ComparatorReportsState,
  CompTrendsReportState,
  SalesReportsState,
  GrossprofitReportsState,
  RankingsReportsState
} from '@mg-platform/reports/reports-data-access'
import { CoreFeatureModule, AuthInterceptor, CustomToastComponent } from '@mg-platform/core/core-feature'
import {
  EnterpriseBenchmarkState,
  EnterpriseMarketPricingsState,
  EnterpriseTrendsState
} from '@mg-platform/enterprise-reports/enterprise-reports-data-access'
import { MatDialogModule } from '@angular/material/dialog'
import { TargetsState } from '@mg-platform/targets/targets-data-access'
import { UserNotificationsState } from '@mg-platform/notifications/notifications-data-access'

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    NgxsModule.forRoot(
      [
        UserAccountState,
        ShopsState,
        StatesState,
        BenchmarkReportsState,
        DailyReportsState,
        MarketPricingReportsState,
        TrendReportsState,
        ShopUsersState,
        UserInvitationState,
        EnterpriseBenchmarkState,
        EnterpriseTrendsState,
        EnterpriseMarketPricingsState,
        WeeklyReportsState,
        ComparatorReportsState,
        CompTrendsReportState,
        TargetsState,
        SalesReportsState,
        GrossprofitReportsState,
        RankingsReportsState,
        FiltersState,
        UserNotificationsState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    SvgIconsModule.forRoot({
      icons: [...commonIcons, ...socialIcons]
    }),
    CoreUiModule,
    MainLayoutModule,
    AuthLayoutModule,
    CoreFeatureModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      toastComponent: CustomToastComponent
    })
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerToken },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class AppModule {}
