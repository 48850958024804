import { Component } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-tippy-tooltip',
  templateUrl: './tippy-tooltip.component.html',
  styleUrls: ['./tippy-tooltip.component.scss']
})
export class TippyTooltipComponent {}
