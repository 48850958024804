<div
  *ngIf="!standalone"
  class="p-3.5 pb-4 lg:p-4 lg:border-b lg:border-ui-separator text-1.25xl leading-2xl font-medium"
>
  Select dates
</div>

<div class="flex-1 flex flex-col lg:flex-row">
  <div *ngIf="!hideAllPeriods" class="pills-container" [ngClass]="{ '!p-3 !pl-1': standalone }">
    <div
      *ngFor="let item of periodTypeButtonsInfo"
      class="mg-datepicker-button"
      [ngClass]="{
        '!bg-primary !border-none !text-white': value?.periodType === item.value
      }"
      (click)="setDateByPeriodType(item)"
    >
      {{ item.label }}
    </div>
  </div>

  <div class="flex-1 flex flex-col">
    <form [formGroup]="rangeForm" class="flex items-center mb-1 -mt-1 px-3.5 pt-4 lg:p-4 lg:pb-0">
      <mg-input
        class="flex-1"
        extraInputClass="!text-lg"
        controlName="fromText"
        label="Start date"
        type="text"
        size="medium"
      ></mg-input>
      <div class="px-0.5 text-txt-secondary">-</div>
      <mg-input
        class="flex-1"
        extraInputClass="!text-lg"
        controlName="toText"
        label="End date"
        type="text"
        size="medium"
      ></mg-input>
    </form>

    <div class="flex-1 px-[10px] pb-0 lg:px-2.5 lg:pb-4" [ngClass]="{ '!pb-0': standalone }">
      <mat-calendar
        #calendar
        class="mg-date-range-picker-calendar"
        [selected]="selectedDateRange"
        (selectedChange)="onSelectedDateChange($event)"
        [maxDate]="maxDate"
        [minDate]="minDate ?? undefined"
      ></mat-calendar>
    </div>
  </div>
</div>

<div
  *ngIf="!standalone"
  class="flex flex-row-reverse items-center justify-end p-3.5 lg:p-4 lg:border-t border-ui-separator"
>
  <mg-button
    class="ml-3 flex-1 lg:flex-auto lg:min-w-[163px] lg:max-w-[163px]"
    size="medium"
    (click)="fireValueChangedEvent()"
  >
    Apply
  </mg-button>
  <mg-button
    class="flex-1 lg:flex-auto lg:min-w-[163px] lg:max-w-[163px]"
    size="medium"
    variant="secondary"
    (click)="canceld.emit()"
  >
    Cancel
  </mg-button>
</div>
