import { ICompareWithSelection } from '@mg-platform/core/core-data-access'
import { MarketType } from '@mg-platform/reports/reports-data-access'

export interface IFilterFormValues {
  shopId: string
  marketType: MarketType
  compareWith: ICompareWithSelection
  weekNumber: number
}

export interface IFilterFields {
  shop?: boolean
  marketType?: boolean
  compareWith?: boolean
  weekNumber?: boolean
}
